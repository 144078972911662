import "./comps.css";

export  function Space({height = "20px"}){
    return (
        <div style={{height: height}}></div>
    )
}
export  function SpaceHorizontal({width = "10px"}){
  return (
      <div style={{width: width}}></div>
  )
}
export function SecondaryBtn({children, onClick}){
    return (
        <button className="secondary-button" onClick={onClick}>
          {children}
        </button>
      );
}

export function PrimaryBtn({children, _width = null, onClick}){
    return (
        <button className="primary-button" style={{width: _width}} onClick={onClick}>
          {children}
        </button>
      );
}

export function RColumn({children, horizontalAlign = "center",verticalAlignment = "start", height = null, maxWidth = null, padding = null}) {
  const style = {
    height: height,
    display: "flex",
    alignItems: horizontalAlign,
    justifyContent: verticalAlignment,
    flexDirection: "column",
    maxWidth: maxWidth,
    padding: padding,
  }
  return (
    <div style={style}>
      {children}
    </div>
  );
}
export function RRow({children, horizontalAlign = "start" ,verticalAlignment = "center", width = null, padding = null}) {
  const style = {
    width: width,
    display: "flex",
    alignItems: verticalAlignment,
    justifyContent: horizontalAlign,
    flexDirection: "row",
    padding: padding,
 
  }
  return (
    <div style={style}>
      {children}
    </div>
  );
}
export function ConnectButton({label = "Connect Wallet"}) {
  return <w3m-button label={label}/>
}