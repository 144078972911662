import { useWeb3ModalProvider, useWeb3ModalAccount, useWeb3Modal, useWeb3ModalEvents } from '@web3modal/ethers/react'
import { BrowserProvider, Contract, formatUnits } from 'ethers'
import { toast } from 'react-toastify';
import { nftAbi } from './collectionAbi';
import { priceNfts } from './App';
import { SecondaryBtn } from './comps/otherCOmps';

const collectionAddress = "0xee8BAa3B9b40290f77ab9E61d76f7c0b1D4566DB";
const prices = [
    1000000000000000,
    1500000000000000,
    2000000000000000,
    2500000000000000,
    3000000000000000
];
const maxSupply = [3000, 2500, 2000, 1500, 1000];

export function MintBtn({id}){
    const { isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    async function mint(){

        if(!isConnected){
            toast.error("Connect wallet first!", {
                position: "top-right",
                autoClose: 5000, // Millisecondi prima che la notifica si chiuda automaticamente
                hideProgressBar: false,
            });
            return;
        }
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner()
        
        const NftContract = new Contract(collectionAddress, nftAbi, signer);
        try{
        await NftContract.mint(id,1, {value: prices[id]});
        toast.success("NFT minted! Check your wallet", {
            position: "top-right",
            autoClose: 3000, // Millisecondi prima che la notifica si chiuda automaticamente
            hideProgressBar: false,
        });
        }catch(e){
            toast.error("Problem with transaction", {
                position: "top-right",
                autoClose: 3000, // Millisecondi prima che la notifica si chiuda automaticamente
                hideProgressBar: false,
            });
            return;
        }
    }
    if(!isConnected) return <></>

    return <SecondaryBtn onClick={() => mint(id)}>Mint<br/>{priceNfts[id]} MATIC</SecondaryBtn>

}
export async function getSupplyOfCategory(walletProvider, index){
    const ethersProvider = new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
    // The Contract object
    const contract = new Contract(collectionAddress, nftAbi, signer)
    const supply = await contract.supply(index);
    return maxSupply[index] - Number(supply);
}