export const Title2 = ({ children }) =>{

    const style = {
        color: "white",
        fontWeight: "bold",
        fontSize: "16px"
       
    };
    return (
        <div style={style}>{children}</div>
    )
}
export const Title1 = ({children, size = "22px", color = "white"}) =>{

    const style = {
        color: color,
        fontWeight: "bold",
        fontSize: size
       
    };
    return (
        <div style={style}>{children}</div>
    )
}
export const Text = ({children, fontSize = "14px" }) =>{

    const style = {
        color: "white",
        fontSize: fontSize
    };
    return (
        <span style={style}>{children}</span>
    )
}