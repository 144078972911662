import "./App.css";
import { ConnectButton, PrimaryBtn, RColumn, RRow, SecondaryBtn, Space } from "./comps/otherCOmps";
import { Text, Title1 } from "./comps/textComps";
import char1Img from "./assets/soldier.png";
import char2Img from "./assets/man.png";
import char3Img from "./assets/ninja.png";
import char4Img from "./assets/donna.png";
import char5Img from "./assets/robot.png";

import char1Imgb from "./assets/soldier.gif";
import char2Imgb from "./assets/man.gif";
import char3Imgb from "./assets/ninja.gif";
import char4Imgb from "./assets/woman.gif";
import char5Imgb from "./assets/robot.gif";

import logoImg from "./assets/logo.png";

import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Link, Route, Routes, useNavigate } from "react-router-dom";
import TermCond from "./termCond";
import { createWeb3Modal, defaultConfig, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import { MintBtn, getSupplyOfCategory } from "./bcNew";

const projectId = '52f8f0001c489d0259c07e49c7743313'

const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com'
}
const testNet = {
  chainId: 80001,
  name: 'Mumbai Testnet',
  currency: 'MATIC',
  explorerUrl: 'https://mumbai.polygonscan.com',
  rpcUrl: 'https://rpc-mumbai.maticvigil.com'
}
const testNetBsc = {
  chainId: 97,
  name: 'BNB Smart Chain Testnet',
  currency: 'tBNB',
  explorerUrl: 'https://explorer.binance.org/smart-testnet',
  rpcUrl: 'https://data-seed-prebsc-1-s1.bnbchain.org:8545'
}

const metadata = {
  name: 'Bullet Last',
  description: 'Mint your NFT now and join the Bullet Last world',
  url: 'https://bulletlast.games', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains:  [testNetBsc],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})
export const priceNfts = [0.001, 0.0015, 0.002, 0.0025, 0.003];

function App() {

  const nameNfts = ["Soldier", "Secret Agent", "Ninja", "Spy girl", "Robot"];
  const imgNfts = [char1Img, char2Img, char3Img, char4Img, char5Img];
  const imgNftsb = [char1Imgb, char2Imgb, char3Imgb, char4Imgb, char5Imgb];
  const [totRemainingToMint, setTotRemainingToMint] = useState({});

  const { isConnected } = useWeb3ModalAccount();
  const {walletProvider} = useWeb3ModalProvider();

  useEffect(() => {
    if(!window.ethereum) return;
    window.ethereum.on('chainChanged', (_chainId) => {
      window.location.reload();
    });
  }, []);
  useEffect(() => {
    console.log(isConnected);
    if(!isConnected) return;
    updateRemaing();
  },[isConnected]);

  async function updateRemaing(){
    let newVal = {};
    for(let i = 0; i < 5; i++){
      newVal[i] = await getSupplyOfCategory(walletProvider,i);
    }
    setTotRemainingToMint(newVal);
  }
 
  function SingleNft({index}){
    return(
      <>
      <div style={{width: "180px"}} className="nftCont">
        <RColumn>
          <img src={imgNfts[index]} height="350px" alt={nameNfts[index]} className={totRemainingToMint[index] === 0 ? "nftImg bwe": "nftImg" }/>
          <img src={imgNftsb[index]} height="350px" alt={nameNfts[index]} className={"nftImgB"}/>
          <Space/>
          <div className="NftTit">{nameNfts[index]}</div>
          <Space/>
          {totRemainingToMint[index] !== 0 &&  <MintBtn id={index}/>}
          {totRemainingToMint[index] === 0 && <Text>Sold out</Text>}
          {totRemainingToMint[index] <= 10 && totRemainingToMint[index] !== 0 && <>
          <Space height="10px"/>
          <Text>Only {totRemainingToMint[index]} remaining!</Text>
          </>}
        </RColumn>
        </div>
      </>
    )
  }

  return (
   <>
    <BrowserRouter>
    <Routes>
      <Route exact path="/terms-conditions" element ={<TermCond/>}/>
      <Route path="/" element={
        <>
         <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />

    <div style={{backgroundColor: "#1A1A1A"}}>
    <RRow horizontalAlign="space-between"  padding="12px 100px">
      <img src={logoImg} height={"80px"}/>
      <ConnectButton/>
    </RRow>
    </div>
    <Space height="40px"/>
    <RColumn>
      <Title1 size="26px">Mint your NFT now and join the Bullet Last world</Title1>
      <Space height="40px"/>
          
      <div style={{display:"flex", flexWrap: "wrap", justifyContent: "space-between", width:"100%"}}>
        <div/>
        {Array.from({ length: 5 }, (_, i) => (
          <SingleNft index={i} key={i}/>
        ))}
      
        <div/>
      </div>
      <Space height="30px"/>
      {!isConnected && <ConnectButton label="Connect Wallet to mint"/>}
      <Space/>
      <RColumn maxWidth={"80%"}>
      <Text>By purchasing or acquiring an NFT from the Bullet Last Initial NFT Collection, you acknowledge that you have read and understood the terms and conditions outlined in this disclaimer, and you confirm that you are aged 18 or older, agreeing to be bound by <Link  to="/terms-conditions" style={{cursor:"pointer", fontWeight:"bold", color: "inherit", textDecoration: "none"}}>these terms and conditions</Link>.</Text>
      </RColumn>
      <Space height="80px"/>
      </RColumn>
      <Space height="200px"/>
          </>
        }/>
    </Routes>
    </BrowserRouter>
     
   </>
  );
}


export default App;
